import { isArray } from "@vue/shared";
import _ from "lodash";

class Helper {
  constructor() {
    this.searchTypes = {
      SERPFinder: 10,
      SitemapAnalyzer: 20,
      TokenAnalysis: 30,
    };
    this.currentSortDir = "asc";
  }
  extractAlert(error, from = null) {
    let alert = { type: "danger", message: error.message };
    let redirect = "";
    if (error.response?.status === 500) {
      alert = {
        type: "danger",
        message: "Something went wrong. Please try again later.",
      };
    } else if (error.response?.status === 404) {
      alert = {
        type: "danger",
        message: "URL Not found",
      };
    } else if (error.response?.status === 401 && from !== "signin") {
      redirect = "signin";
      alert = {
        type: "danger",
        message: "Invalid API token. Please login again",
      };
    } else if (error.response?.data) {
      const firstKey = Object.keys(error.response.data)[0];
      let message = error.response.data?.[firstKey];
      if (isArray(message)) {
        message = message[0];
      }
      if (from === "signup" || from === "serp-finder") {
        message = `${firstKey}: ${message}`;
      }
      alert = {
        type: "danger",
        message: message,
      };
    }
    return { alert, redirect };
  }
  sortEntities(obj, key, order = false) {
    this.toggleSortDir(order);
    const nullOrUndefinedFirst = this.currentSortDir === "asc" ? -1 : 1;
    return obj.sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];
      if (aValue === null || aValue === undefined) {
        return nullOrUndefinedFirst;
      }
      if (bValue === null || bValue === undefined) {
        return -nullOrUndefinedFirst;
      }
      return typeof aValue === "number" && typeof bValue === "number"
        ? this.currentSortDir === "asc"
          ? aValue > bValue
            ? 1
            : -1
          : bValue > aValue
          ? 1
          : -1
        : this.currentSortDir === "asc"
        ? aValue?.localeCompare(bValue)
        : bValue?.localeCompare(aValue);
    });
  }
  lodashOrder(obj, key, order = false) {
    this.toggleSortDir(order);
    return _.orderBy(
      obj,
      [
        (item) => {
          return typeof item[key] === "number"
            ? item[key]
            : _.get(item, key)?.toLowerCase();
        },
      ],
      order ? order : this.currentSortDir
    );
  }
  toggleSortDir(order) {
    if (order) {
      this.currentSortDir = order;
    } else {
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
    }
  }
  async copyToClipboard(mytext) {
    try {
      await navigator.clipboard.writeText(mytext);
      return {
        alertType: "default",
        message: "Copied to clipboard",
      };
    } catch ($e) {
      return {
        alertType: "error",
        message: "Cannot copy",
      };
    }
  }
  getSearchTypeByName(typeName) {
    if (typeName == "Sitemap Analysis") {
      return this.searchTypes.SitemapAnalyzer;
    }
    return this.searchTypes.SERPFinder;
  }
  async export(data, fileName) {
    const anchor = document.createElement("a");
    anchor.href =
      "data:text/csv;charset=utf-8," + encodeURIComponent(data.join("\n"));
    anchor.target = "_blank";
    anchor.download = fileName;
    anchor.click();
  }
}

export default new Helper();
