import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("../views/DashboardView.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/accounts/resend-email-confirmation",
      name: "resend-email-confirmation",
      component: () => import("../views/ResendEmailConfirmation.vue"),
    },
    {
      path: "/accounts/login",
      name: "signin",
      component: () => import("../views/SignIn.vue"),
    },
    {
      path: "/accounts/team/signup/:uuid",
      name: "team-signup",
      component: () => import("../views/SignUp.vue"),
    },
    {
      path: "/accounts/signup",
      name: "signup",
      component: () => import("../views/SignUp.vue"),
    },
    {
      path: "/accounts/success-signup",
      name: "success-signup",
      component: () => import("../views/SuccessSignUp.vue"),
    },
    {
      path: "/accounts/signup-2",
      name: "signup2",
      component: () => import("../views/Signup2View.vue"),
    },
    {
      path: "/accounts/password-reset",
      name: "password-reset",
      component: () => import("../views/ForgetPasswordFormView.vue"),
    },
    {
      path: "/accounts/password-reset/:uuid",
      name: "password-reset-set-password",
      component: () => import("../views/ForgetPasswordSetPasswordView.vue"),
    },
    {
      path: "/accounts/settings",
      name: "account",
      component: () => import("../views/AccountView.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/accounts/team",
      name: "team-members",
      component: () => import("../views/TeamMembersView.vue"),
      meta: {
        requireAuth: !0,
        requireTeamAccess: !0,
      },
    },
    {
      path: "/payments/order",
      name: "account-subscription",
      component: () => import("../views/SubscriptionView.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/payments/callback/cancel",
      name: "subscription-cancelled",
      component: () => import("../views/payment/CancelledView.vue"),
    },
    {
      path: "/payments/callback/success",
      name: "subscription-confirmed",
      component: () => import("../views/payment/ConfirmedView.vue"),
    },
    {
      path: "/payments/callback/upgrade",
      name: "subscription-upgrade",
      component: () => import("../views/payment/UpgradeView.vue"),
    },
    {
      path: "/serp/keyword-spy",
      name: "serp-keyword-spy",
      component: () => import("../views/SerpFinderView.vue"),
      meta: {
        requireAuth: !0,
        permissions: ["services.token_analysis"],
      },
    },
    {
      path: "/serp/reports/:uuid",
      name: "serp-report-ta",
      component: () => import("../views/TAReportView.vue"),
      meta: {
        requireAuth: !0,
        permissions: ["services.token_analysis"],
      },
    },
    {
      path: "/serp/reports",
      name: "reports",
      component: () => import("../views/ReportsView.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/serp/projects",
      name: "projects",
      component: () => import("../views/ProjectsView.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/serp/projects/:uuid",
      name: "edit-project",
      component: () => import("../views/EditProject.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    // {
    //   path: "/keyword-discovery",
    //   name: "CSVToKeyword",
    //   component: () => import("../views/CSVToKeywordReportView.vue"),
    //   meta: {
    //     requireAuth: !0,
    //     permissions: ["services.keyword_explorer"],
    //   },
    // },
    {
      path: "/keyword-discovery",
      name: "KeywordPlanner",
      component: () => import("../views/KeywordPlannerView.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/keyword-discovery/:uuid",
      name: "KeywordPlannerDetail",
      component: () => import("../views/KeywordPlannerDetailView.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/upload/callback/:service",
      name: "callback-file-upload",
      component: () => import("../views/callback/UploadCallbackView.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/ai-spy/:uuid?",
      name: "zebra-ai",
      component: () => import("../views/ZebraAIView.vue"),
      meta: {
        requireAuth: !0,
        permissions: ["services.spy_ai"],
      },
    },
    {
      path: "/article-builder/:uuid?",
      name: "editor",
      component: () => import("../views/EditorView.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/article-builder/public/:uuid",
      name: "editor-public",
      component: () => import("../views/EditorView.vue"),
    },
    // {
    //   path: "/serp/audits",
    //   name: "audits",
    //   component: () => import("../views/AuditsView.vue"),
    //   meta: {
    //     requireAuth: !0,
    //   },
    // },
    // {
    //   path: "/serp/audits/:uuid",
    //   name: "AuditsDetails",
    //   component: () => import("../views/AuditsDetails.vue"),
    //   meta: {
    //     requireAuth: !0,
    //   },
    // },
    // {
    //   path: "/audit",
    //   name: "audit",
    //   component: () => import("../views/AuditBetaView.vue"),
    //   meta: {
    //     requireAuth: !0,
    //   },
    // },
    // {
    //   path: "/audit/:uuid",
    //   name: "audit-details",
    //   component: () => import("../views/AuditBetaDetailsView.vue"),
    //   meta: {
    //     requireAuth: !0,
    //   },
    // },
    {
      path: "/audit",
      name: "audit",
      component: () => import("../views/AuditBetaView.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/audit/:uuid",
      name: "audit-details",
      component: () => import("../views/AuditBetaDetailsView.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/wordpress-authentication",
      name: "wordpress-authentication",
      component: () => import("../views/WordpressAuthentication.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/integrations",
      name: "integrations",
      component: () => import("../views/IntegrationsView.vue"),
      meta: {
        requireAuth: !0,
      },
    },
    {
      path: "/freeseoaudit",
      name: "freeseoaudit",
      component: () => import("../views/FreeSeoAuditView.vue"),
      meta: {
        requireAuth: !1,
        isHideSidebar: true,
      },
    },
    {
      path: "/freeseoaudit/:uuid",
      name: "FreeSeoAuditDetailView",
      component: () => import("../views/FreeSeoAuditDetailView.vue"),
      meta: {
        requireAuth: !1,
        isHideSidebar: true,
      },
    },
    {
      path: "/accounts/verify",
      name: "account-verify",
      component: () => import("../views/AccountVerify.vue"),
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () => import("../views/NotificationsView.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      name: "error-view",
      component: () => import("../views/Error.vue"),
      meta: {
        isHideSidebar: true,
      },
    },
    {
      path: "/seotraining",
      name: "seotraining",
      component: () => import("../views/SeoTraining.vue"),
      meta: {
        isHideSidebar: true,
        title: "Unlock Up-to-Date SEO Tactics That Work: SEO University",
      },
    },
    {
      path: "/activate-promo",
      name: "activate-promo",
      component: () => import("../views/AppsumoActiveSubscription.vue"),
      meta: {
        isHideSidebar: true,
        requireAuth: !0,
      },
    },
    {
      path: "/maintenance",
      name: "maintenance",
      component: () => import("../views/MaintenanceView.vue"),
      meta: {
        isHideSidebar: true,
        requireAuth: !0,
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  try {
    window.document.title =
      to.meta && to.meta.title ? to.meta.title : "SEO Copilot";
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : false;
    const {
      meta: {
        requireAuth = false,
        permissions = [],
        requireTeamAccess = false,
        superUserOnly = false,
      } = {},
    } = to;
    const isMaintenanceMode = false;
    if (to.path !== "/maintenance" && isMaintenanceMode) {
      next("/maintenance");
    } else {
      next();
    }
    if (
      user &&
      (to.name === "signin" ||
        to.name === "team-signup" ||
        to.name === "signup" ||
        to.name === "password-reset" ||
        to.name === "password-reset-set-password")
    ) {
      if (to.query && to.query.hasOwnProperty("plan")) {
        return "/payments/order?plan=" + to.query["plan"];
      } else {
        return "/";
      }
    }
    if (requireAuth && !user) {
      throw new Error("Not loggedin.");
    }
    if (superUserOnly && !user.is_superuser) {
      throw new Error("Permission denied.");
    }
    if (requireTeamAccess && !user.team_tier) {
      throw new Error("Permission denied.");
    }
    if (permissions.length > 0) {
      const hasPermission = user.permissions.some((permission) =>
        permissions.includes(permission)
      );
      if (!hasPermission) {
        throw new Error("Permission denied.");
      }
    }
  } catch (error) {
    return `/accounts/login?redirectUrl=${to.fullPath}`;
  }
});
router.afterEach((to) => {
  if (window.productFruits) {
    const user = localStorage.getItem("user") || false;
    if (user && window.productFruits.identifyUser) {
      const { email, first_name, last_name, phone_number } = user;
      window.productFruits.identifyUser({
        username: email,
        email,
        first_name,
        last_name,
        phone_number,
        props: {
          uuid: (to.params && to.params.uuid) || "",
          routeName: to.name || "",
        },
      });
      $productFruits.push(["pageChanged"]);
    } else {
      $productFruits.push(["pageChanged"]);
    }
  }
});
export default router;
