import axios from "axios";

function ApiRequest(
  access_token = false,
  fullURL = false,
  appNameHeader = true
) {
  if (!access_token) {
    access_token = localStorage.getItem("access_token") || false;
  }
  const isPublicLink = location.pathname.includes("article-builder/public");
  if (access_token && access_token !== "skip" && !isPublicLink) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
  }
  if (appNameHeader) {
    axios.defaults.headers.common["x-app-name"] = import.meta.env.VITE_APP_NAME;
  }
  if (!fullURL) {
    axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
  }
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      if (err.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(err);
        });
      }
      if (err.response.config.url === "/api/auth/token/obtain") {
        return new Promise((resolve, reject) => {
          reject(err);
        });
      }
      if (err.response.config.url === "/api/auth/token/refresh") {
        return new Promise((resolve, reject) => {
          reject(err);
        });
      }
      if (access_token && access_token !== "skip" && !isPublicLink) {
        try {
          const success = await axios.post("/api/auth/token/refresh", {
            refresh: localStorage.getItem("refresh_token") || "",
          });
          const config = err.response.config;
          const newAccessToken = success.data.access || "";
          localStorage.setItem("access_token", newAccessToken);
          config.headers.Authorization = `Bearer ${newAccessToken}`;
          return await axios(config);
        } catch (error) {
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          window.location.href = "/accounts/login";
          return new Promise((resolve, reject) => {
            reject(err);
          });
        }
      } else if (!access_token && !isPublicLink) {
        window.location.href = "/accounts/login";
        return new Promise((resolve, reject) => {
          reject(err);
        });
      }
    }
  );
  return axios;
}

export default ApiRequest;
